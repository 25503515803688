<template>
  <b-col cols="12" md="4" lg="3">
    <b-card 
      class="product-card d-flex flex-column" 
      :class="{ 'in-delete-mode': isDeleteMode, 'selected': isSelected }"
      no-body
      @click="handleCardClick"
    >
    <div class="position-relative image-container">
  <!-- Selection Overlay (always present but only visible in delete mode) -->
  <div 
    class="selection-overlay" 
    :class="{ 
      'visible': isDeleteMode,
      'selected': isSelected 
    }"
  >
    <div class="selection-checkbox">
      <feather-icon 
        size="24" 
        :icon="isSelected ? 'CheckSquareIcon' : 'SquareIcon'"
        class="checkbox-icon"
        :class="{ 'selected': isSelected }"
      />
    </div>
  </div>
        <b-img 
          fluid 
          class="card-img-top product-list-img-object-fit" 
          :src="computedImageUrl" 
          @click="handleImageClick"
        />
        <div class="position-absolute top-right-icons">
          <div 
            v-if="showLikeButton"
            class="top-right-icon love-icon"
            :class="product.like_or_dislike === 'Y' ? 'filled-heart' : 'empty-heart'"
            @click="toggleLike">
            <feather-icon size="24" icon="HeartIcon" :fill="product.like_or_dislike === 'Y' ? 'red' : 'none'" />
          </div>
          <div 
            v-if="showChatButton" 
            class="top-right-icon share-icon"
            @click="initializePostCreation">
            <feather-icon size="24" icon="MessageCircleIcon" />
          </div>
          <div 
            v-if="showShareButton" 
            class="top-right-icon share-icon"
            @click="shareImage('web')">
            <feather-icon size="24" icon="Share2Icon" />
          </div>
        </div>
      </div>
      <b-card-body v-if="showDownloadButton" class="d-flex flex-column p-0">
        <b-button  block class="cart-btn mt-auto no-border-radius" @click="downloadImage(computedImageUrl)" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary">
          <feather-icon icon="ArrowDownCircleIcon" class="mr-25" />
          Download
        </b-button>
      </b-card-body>

      <b-card-body v-if="showPurchaseButton" class="d-flex flex-column p-0">
        <b-button  block class="cart-btn mt-auto no-border-radius" @click="handlePurchase" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary">
          <feather-icon icon="ShoppingBagIcon" class="mr-25" />
          Buy Now
        </b-button>
      </b-card-body>

      <!-- Image Modal -->
      <b-modal 
        v-model="showModal" 
        hide-header 
        hide-footer 
        centered 
        size="md" 
      
        @hidden="showModal = false" 
        content-class="p-0 modal-full-width"
        dialog-class="modal-90w modal-no-margin" 
        body-class="p-0"
      >
        <div class="modal-carousel-container">
          <!-- Close Button -->
          <!-- <div class="modal-close-container">
            <button type="button" class="close clear-button" @click="showModal = false">&times;</button>
          </div> -->

          <!-- Navigation Arrows -->
          <!-- <button 
            class="nav-button nav-prev" 
            @click="navigateImages('prev')"
            v-show="currentImageIndex > 0"
          >
            <feather-icon icon="ChevronLeftIcon" size="48" />
          </button> -->

          <!-- <button 
            class="nav-button nav-next" 
            @click="navigateImages('next')"
            v-show="currentImageIndex < totalImages - 1"
          >
            <feather-icon icon="ChevronRightIcon" size="48" />
          </button> -->

          <!-- Image Container with Touch Support -->
          <div 
            class="image-carousel"
            @touchstart="handleTouchStart"
            @touchmove="handleTouchMove"
            @touchend="handleTouchEnd"
          >
            <transition-group name="slide">
              <img 
              :key="currentImageIndex"
                :src="currentImage?.image_url || computedImageUrl" 
                class="carousel-image"
                :class="{ 'transitioning': isTransitioning }"
              />
            </transition-group>
          </div>

          <!-- Optional: Image Counter -->
          <!-- <div class="image-counter">
            {{ currentImageIndex + 1 }} / {{ totalImages }}
          </div> -->
        </div>
      </b-modal>
    </b-card>
  </b-col>
</template>

<script>
import Ripple from 'vue-ripple-directive';
import { EventBus } from '@/event-bus';
import _ from 'lodash';
export default {
  name: 'ImageSharingComponent',
  props: {
    product: {
      type: Object,
      required: true,
    },
    showLikeButton: {
      type: Boolean,
      default: true,
    },
    showShareButton: {
      type: Boolean,
      default: true,
    },
    showChatButton: {
      type: Boolean,
      default: true,
    },
    showDownloadButton: {
      type: Boolean,
      default: true,
    },
    showPurchaseButton: {
      type: Boolean,
      default: false,
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
    allImages: {
      type: Array,
      default: () => []
    },
    isDeleteMode: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showModal: false,
      showShareOptions: false,
      currentImageIndex: 0,
      isTransitioning: false,
      touchStartX: null,
      touchEndX: null,
    };
  },
  directives: {
    Ripple,
  },
  computed: {
    computedImageUrl() {
      return this.product.image_url || this.product.imageUrl || this.image?.imageUrl;
    },
    currentImage() {
  return this.allImages[this.currentImageIndex] || this.product;
},
totalImages() {
  return this.allImages?.length || 1;
},
showImageCounter() {
  return this.totalImages > 1;
}
  },
  methods: {

    openModal() {
      this.currentImageIndex = this.allImages.findIndex(img => 
        img.image_url === this.computedImageUrl
      );
      this.showModal = true;
    },
    

    navigateImages(direction) {
      this.isTransitioning = true;
      if (direction === 'next' && this.currentImageIndex < this.totalImages - 1) {
        this.currentImageIndex++;
      } else if (direction === 'prev' && this.currentImageIndex > 0) {
        this.currentImageIndex--;
      }
      setTimeout(() => {
        this.isTransitioning = false;
      }, 300);
    },

    // Touch handling methods
    handleTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
    },

    handleTouchMove(event) {
      this.touchEndX = event.touches[0].clientX;
    },

    handleTouchEnd() {
      if (!this.touchStartX || !this.touchEndX) return;
      
      const diffX = this.touchStartX - this.touchEndX;
      const threshold = 50; // minimum distance for swipe

      if (Math.abs(diffX) > threshold) {
        if (diffX > 0) {
          // Swipe left
          this.navigateImages('next');
        } else {
          // Swipe right
          this.navigateImages('prev');
        }
      }

      // Reset touch coordinates
      this.touchStartX = null;
      this.touchEndX = null;
    },

    handleCardClick(e) {
      if (this.isDeleteMode) {
        e.preventDefault();
        e.stopPropagation();
        this.$emit('toggle-selection', this.product.id);
      }
    },

    handleImageClick(e) {
    if (this.isDeleteMode) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit('toggle-selection', this.product.id);
    } else {
      this.showModal = true;
    }
  },

  async handlePurchase() {
      // Check for user login
      const user = JSON.parse(localStorage.getItem('userData'));
      const userId = user?.userId;

      if (!userId) {
        const loginSuccess = await this.showLoginModal();
        if (!loginSuccess) {
          return;
        }
      }

      // After successful login check for product URL
      if (this.product?.productUrl) {
        // Add tracking parameters
        const trackingUrl = new URL(this.product.productUrl);
        trackingUrl.searchParams.append('utm_source', 'briks.ai');
        trackingUrl.searchParams.append('utm_medium', 'referral');
        trackingUrl.searchParams.append('utm_campaign', 'product_purchase');
        
        // Open in new tab
        window.open(trackingUrl.toString(), '_blank');
      } else {
        // Show toast for product unavailability
        this.$bvToast.toast('Sorry, this product not available for purchase at this moment.', {
          title: 'Product not available',
          variant: 'warning',
          solid: true,
          toaster: 'b-toaster-top-center',
          appendToast: true,
          autoHideDelay: 3000
        });
    
      }
    },

  async downloadImage(url) {
  const user = JSON.parse(localStorage.getItem('userData'));
  const userId = user?.userId;

  if (!userId) {
    const loginSuccess = await this.showLoginModal();
    if (!loginSuccess) {
      return;
    }
  }

  try {
    // Check if it's a mobile device (Android/iOS)
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isAndroid = /Android/.test(navigator.userAgent);

    // For iOS and Android, trigger the share option
    if (isIOS || isAndroid) {
      if (navigator.share) {
        // Use the Web Share API if available
        await navigator.share({
          title: 'Check out this image!',
          text: 'I found this amazing image',
          url: url,
        });
        this.$bvToast.toast('Shared successfully!', {
          title: 'Share Success',
          variant: 'success',
          solid: true
        });
      } else {
        // For iOS without Web Share API, open in new tab
        window.open(url, '_blank');
      }
    } else {
      // For desktop or browsers that do not support Web Share API
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = url.split('/').pop(); // Name the file by the last part of the URL
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
      
      this.$bvToast.toast('Image downloaded successfully!', {
        title: 'Download Success',
        variant: 'success',
        solid: true
      });
    }
  } catch (error) {
    // console.error('Error downloading/sharing the image', error);
    // this.$bvToast.toast('Failed to download or share the image. Please try again.', {
    //   title: 'Error',
    //   variant: 'danger',
    //   solid: true
    // });
  }
},

    initializePostCreation: _.debounce(function() {
  const imageUrl = this.computedImageUrl;
  const prompt = this.product.prompt;
  // console.log('Emitting initializePostCreation event with:', { imageUrl, prompt });
  // Emit event with chat data
  this.$store.commit("app/SET_INITIALIZE_CHAT", true);
  EventBus.$emit('initializePostCreation', { imageUrl, prompt });
}, 500),


    showLoginModal() {
      return new Promise((resolve) => {
        this.$bvModal.show('modal-login');
        const handleLoginSuccess = () => {
          resolve(true);
          cleanup();
        };
        const handleLoginFailure = () => {
          resolve(false);
          cleanup();
        };
        const cleanup = () => {
          this.$root.$off('login-success', handleLoginSuccess);
          this.$root.$off('login-failure', handleLoginFailure);
        };
        this.$root.$on('login-success', handleLoginSuccess);
        this.$root.$on('login-failure', handleLoginFailure);
      });
    },
    toggleLike() {
      const newStatus = this.product.like_or_dislike === 'Y' ? 'N' : 'Y';
      this.$emit('toggle-like', this.product.id, newStatus);
    },
    toggleShareOptions() {
      this.showShareOptions = !this.showShareOptions;
    },
    async shareImage(platform) {
      const url = this.computedImageUrl;
      const text = 'See what I found in briks.ai';

      try {
        if (!navigator.share) {
          console.log('Web Share API not supported, using fallback');
          this.shareFallback(platform, text, url);
          return;
        }

        const response = await fetch(url);
        if (!response.ok) throw new Error('Network response was not ok');
        const blob = await response.blob();
        const fileName = url.split('/').pop();
        const file = new File([blob], fileName, { type: blob.type });

        const shareData = {
          title: 'Check out this image',
          text: text,
          url: window.location.href
        };

        try {
          shareData.files = [file];
          await navigator.share(shareData);
          console.log('Shared successfully with file');
        } catch (fileError) {
          console.warn('File sharing failed, trying without file', fileError);
          delete shareData.files;
          await navigator.share(shareData);
          console.log('Shared successfully without file');
        }

        this.$bvToast.toast('Image shared successfully!', {
          title: 'Share Success',
          variant: 'success',
          solid: true
        });
      } catch (error) {
        console.error('Sharing failed:', error);
        this.shareFallback(platform, text, url);
        // this.$bvToast.toast('Failed to share image. Using fallback method.', {
        //   title: 'Share Error',
        //   variant: 'warning',
        //   solid: true
        // });
      }
    },
    shareFallback(platform, text, url) {
      let shareUrl = '';

      switch (platform) {
        case 'whatsapp':
          shareUrl = `https://wa.me/?text=${encodeURIComponent(text + ' ' + url)}`;
          break;
        case 'twitter':
          shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`;
          break;
        case 'facebook':
          shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
          break;
        case 'linkedin':
          shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;
          break;
        case 'web':
          const encodedUrl = encodeURIComponent(url);
          const fullText = `https://briks.ai/image?url=${encodedUrl}`;
          if (navigator.clipboard) {
            navigator.clipboard.writeText(fullText)
              .then(() => {
                this.$bvToast.toast('Link copied to clipboard!', {
                  title: 'Copy Success',
                  variant: 'success',
                  solid: true
                });
              })
              .catch((error) => {
                console.error('Error copying to clipboard:', error);
                this.showManualCopyDialog(fullText);
              });
          } else {
            this.showManualCopyDialog(fullText);
          }
          return;
        default:
          console.error('Unsupported platform:', platform);
          return;
      }

      if (shareUrl) {
        window.open(shareUrl, '_blank');
      }
    },
  },
};
</script>

<style scoped>
  .bottom-right-icon {
  align-self: flex-end; /* Aligns button to the bottom right */
  position: relative;
  margin-top: auto; /* Ensures the button stays at the bottom */
  cursor: pointer;
  margin-right: 4.2px;
  color:rgb(253, 1, 1);
}

.image-container {
  height: auto; 
  position: relative;
  overflow: hidden;
}

.isDeleteMode .top-right-icons {
  display: none;
}

.card-img-top {
  height: 100%;
  width: 100%;
  object-fit: fill;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.position-relative {
  position: relative;
}
.top-right-icons {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  flex-direction: column;
}
.top-right-icon {
  margin-bottom: 5px;
  cursor: pointer;
}
.share-options {
  display: flex;
  flex-direction: column;
}
.cart-btn {
  margin: 0;
  padding: 10px;
}
.no-border-radius {
  border-radius: 0 !important;
}
.love-icon, .share-icon {
  width: auto;
  height: auto;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255);
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 55%;
}
.filled-heart {
  color: red !important;
}
.empty-heart {
  color: rgb(0, 0, 0) !important;
}
.modal-90w {
  max-width: 90%;
}
.modal-no-margin .modal-dialog {
  margin: 0 !important;
  padding: 0 !important;
  background: transparent;
}
.modal-no-margin .modal-content {
  border: none !important;
  background: transparent;
}
.modal-close-container {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1051;
}
.clear-button {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  opacity: 0.8;
}
.clear-button:hover {
  opacity: 1;
}
.card {
  margin-bottom: 1rem !important;
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}


.modal-carousel-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-carousel {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-color: transparent;
}

.carousel-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1052;
  transition: all 0.3s ease;
}

.nav-button:hover {
  background: rgba(255, 255, 255, 0.9);
}

.nav-prev {
  left: 16px;
}

.nav-next {
  right: 16px;
}

.image-counter {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 14px;
}

.selection-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  display: none;
  /* Change alignment to bottom-right */
  align-items: flex-end; /* Changed from flex-start */
  justify-content: flex-end;
  padding: 10px;
  z-index: 10;
  transition: background-color 0.2s ease;
}

.selection-overlay.visible {
  display: flex; /* Show when in delete mode */
}

.selection-overlay.selected {
  display: flex;
  background-color: rgba(0, 123, 255, 0.1);
}

.selection-indicator {
  background-color: white;
  border-radius: 4px;
  padding: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.checkbox-icon {
  color: #DC123E;
  transition: color 0.2s ease;
}

.checkbox-icon.selected {
  color: #DC123E;
}

.in-delete-mode {
  cursor: pointer;
}
c
.in-delete-mode .card-img-top {
  transition: filter 0.2s ease;
}

.in-delete-mode.selected .card-img-top {
  filter: brightness(0.9);
}

.in-delete-mode .cart-btn {
  margin-bottom: 3.5rem !important;
}

.in-delete-mode .top-right-icons {
  display: flex !important;
  z-index: 12; /* Ensure buttons stay on top */
}

.selection-checkbox:hover {
  transform: scale(1.1);
}

.selection-overlay.visible {
  display: flex;
}

selection-checkbox {
  position: absolute;
  bottom: 1rem;  /* Position from bottom */
  right: 1rem;   /* Position from right */
  background-color: #DC123E;
  border-radius: 4px;
  padding: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 11;
  transition: all 0.2s ease;
}

/* Transition animations */
.slide-enter-active, .slide-leave-active {
  transition: transform 0.3s ease-in-out;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-leave-to {
  transform: translateX(-100%);
}

.transitioning {
  transition: transform 0.3s ease-in-out;
}

</style>